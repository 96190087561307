/* кнопка меню */
try{
    let burgerBtn = document.querySelector('.topnavs__nav_burger_btn');
    let burgerMenu = document.querySelector('.topnavs__nav_menu1');

    burgerBtn.addEventListener('click', function () {
        if (burgerBtn.classList.contains('topnavs__nav_burger_btn-active')) {
            burgerBtn.classList.remove('topnavs__nav_burger_btn-active');
            burgerMenu.style.display = 'none';
        } else {
            burgerBtn.classList.add('topnavs__nav_burger_btn-active');
            burgerMenu.style.display = 'flex';
        }
    });

}catch(e){
    console.log(e);
}
/* добавление фона на фиксированное меню */
let pageWidth = window.outerWidth;
//console.log(pageWidth);
try {
    window.addEventListener('scroll', function () {
        let scrollTop = window.pageYOffset;
        if (document.querySelector('#topnavs')) {
            if (pageWidth > 500) {
                if (scrollTop >= 100) {
                    document.querySelector('#topnavs').classList.add('topnavs__scroll');
                } else {
                    document.querySelector('#topnavs').classList.remove('topnavs__scroll');
                }
            } else {
                if (scrollTop >= 50) {
                    document.querySelector('#topnavs').classList.add('topnavs__scroll');
                } else {
                    document.querySelector('#topnavs').classList.remove('topnavs__scroll');
                }
            }
        }
    });
}catch(e){
    console.log(e);
}



/* текст кнопочки личный кабинет меняем на мобильном устройстве */
try {
    if (document.querySelector('#bbtn_lk_mobile')) {
        let bbtnLkMobile = document.querySelector('#bbtn_lk_mobile');
        if (pageWidth < 567) {
            let getBbtnLkMobile = bbtnLkMobile.innerHTML
            let miniBbtnLkMobile = getBbtnLkMobile.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');
            bbtnLkMobile.innerHTML = miniBbtnLkMobile.toUpperCase();
        }
    }
}catch(e){
    console.log(e);
}



/* динамическая смена переменной в h1 */
// if (document.querySelector('.header_image--content h2')) {
//     let headerVar = document.querySelector('.header_image--content h2');
//     let varText = [
//         "<span class='header_image--content-anim1'>Взять деньги в долг</span>",
//         "<span class='header_image--content-anim2'>Дать деньги в долг</span>",
//         "<span class='header_image--content-anim3'>Купить исполнительный лист</span>",
//         "<span class='header_image--content-anim4'>Продать исполнительный лист</span>"
//     ];
//
//     function changeText() {
//         let varRandom = Math.round(Math.random() * 3);
//         headerVar.innerHTML = varText[varRandom];
//     }
//     setInterval(changeText, 5000);
// }

/* переключение табов в модальном окне */
try {
    let tabModal = function () {
        let tabNav = document.querySelectorAll('.modal_lk__tabs_tab-item');
        let tabContent = document.querySelectorAll('.modal_lk__tabs_tabContent-item');
        let tabName;
        tabNav.forEach(item => {
            item.addEventListener('click', selectTabNav)
        });

        function selectTabNav() {
            tabNav.forEach(item => {
                item.classList.remove('modal_lk__tabs_tab-item-active');
            });
            this.classList.add('modal_lk__tabs_tab-item-active');
            tabName = this.getAttribute('data-tab-name');
            selectTabContent(tabName);
        }

        function selectTabContent(tabName) {
            tabContent.forEach(item => {
                item.classList.contains(tabName) ? item.classList.add('modal_lk__tabs_tabContent-item-active') : item.classList.remove('modal_lk__tabs_tabContent-item-active');
            });
        }
    };
    tabModal();
}catch(e){
    console.log(e);
}

/* переключение табов таблиц */
try {
    let tabTable = function () {
        let tabTableNav = document.querySelectorAll('.tables__tab_item');
        let tabTableContent = document.querySelectorAll('.tables__tabContent_item');
        let tabTableName;
        tabTableNav.forEach(item => {
            item.addEventListener('click', selectTabNav)
        });

        function selectTabNav() {
            tabTableNav.forEach(item => {
                item.classList.remove('tables__tab_item-active');
            });
            this.classList.add('tables__tab_item-active');
            tabTableName = this.getAttribute('data-tab-name');
            selectTabContent(tabTableName);
        }

        function selectTabContent(tabTableName) {
            tabTableContent.forEach(item => {
                item.classList.contains(tabTableName) ? item.classList.add('tables__tabContent_item-active') : item.classList.remove('tables__tabContent_item-active');
            });
        }
    };
    tabTable();
}catch(e){
    console.log(e);
}


/* слайдер статьи */

$(document).ready(function () {
    /* статьи */
    try {
        $('.articles__slider').slick({
            arrows: true,
            dots: true,
            /* для адаптивной высоты */
            adaptiveHeight: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            easing: 'ease',
            infinite: true,
            initialSlide: 0,
            autoplay: true,
            autoplaySpeed: 9000,
            pauseOnFocus: true,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            draggable: true,
            swipe: true,
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 777,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        arrows: false
                    }
                }
            ]
        });
    }catch (e){
        console.log(e);
    }
    /* отзывы */
    try{
        $('.reviews__slider').slick({
            arrows: true,
            dots: true,
            adaptiveHeight: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            speed: 1000,
            easing: 'ease',
            infinite: true,
            initialSlide: 0,
            autoplay: false,
            autoplaySpeed: 9000,
            pauseOnFocus: true,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            draggable: true,
            swipe: true,
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 770,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        arrows: true
                    }
                }
            ]
        });
    }catch (e){
        console.log(e);
    }

    /* горячие предложения */
    try {
        $('.hot_deals__slider').slick({
            centerMode: false,
            arrows: true,
            dots: true,
            adaptiveHeight: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            speed: 1000,
            easing: 'ease',
            infinite: true,
            initialSlide: 0,
            autoplay: false,
            autoplaySpeed: 4000,
            pauseOnFocus: true,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            draggable: true,
            swipe: true,
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        arrows: false
                    }
                }
            ]
        });
    }catch (e){
        console.log(e);
    }

});

/* делаем карточку квадратной  hot_deals__slider_item*/
try {
    if (document.querySelector('.hot_deals__slider_item')) {
        let pageWidth = window.outerWidth;
        const hotDealsSliderItem = document.querySelectorAll('.hot_deals__slider_item');
        if (pageWidth > 992) {
            hotDealsSliderItem.forEach(item => {
                let oWidth = item.offsetWidth;
                console.log(oWidth + ' > 992 ');
                item.style.height = ((oWidth - 100) / 3) - 16 + 'px';
            });
        } else if (pageWidth < 992 && pageWidth > 576) {
            hotDealsSliderItem.forEach(item => {
                let oWidth = item.offsetWidth;
                console.log(oWidth + ' < 992 ');
                item.style.height = ((oWidth - 100) / 2) - 16 + 'px';
            });
        } else if (pageWidth < 576) {
            hotDealsSliderItem.forEach(item => {
                let oWidth = item.offsetWidth;
                console.log(oWidth + ' < 576 ');
                item.style.height = (oWidth - 100) + 'px';
            });
        }
    }
}catch (e){
    console.log(e);
}

/* уменьшем меню навигации в лк */

try{
    const lkHeaderContentBurgerBtn = document.querySelector('.lk_header__content_burger_btn');
    const lkContentMenu = document.querySelector('.lk_content__menu');
    const lkContentContent = document.querySelector('.lk_content__content');

    let pageWidth = window.outerWidth;
    if (pageWidth < 768){
        lkContentMenu.classList.add('lk_content__menu-small');
        lkHeaderContentBurgerBtn.addEventListener('click', function () {
            if(lkContentMenu.classList.contains('lk_content__menu-small')){
                lkContentMenu.classList.remove('lk_content__menu-small');
                lkHeaderContentBurgerBtn.classList.remove('lk_header__content_burger_btn-close');
                lkContentContent.classList.add('lk_content__content-shift');

            }else{
                lkContentMenu.classList.add('lk_content__menu-small');
                lkHeaderContentBurgerBtn.classList.add('lk_header__content_burger_btn-close');
                lkContentContent.classList.remove('lk_content__content-shift');
            }

        });
    }else{
        lkHeaderContentBurgerBtn.addEventListener('click', function () {
            if(lkContentMenu.classList.contains('lk_content__menu-small')){
                lkContentMenu.classList.remove('lk_content__menu-small');
                lkHeaderContentBurgerBtn.classList.remove('lk_header__content_burger_btn-close');
            }else{
                lkContentMenu.classList.add('lk_content__menu-small');
                lkHeaderContentBurgerBtn.classList.add('lk_header__content_burger_btn-close');
            }

        });
    }
}catch (e){
    console.log(e);
}

/* переключение табов */
try {
    let tab = function () {
        let tabNav = document.querySelectorAll('.lk_content__purse_tab-item');
        let tabContent = document.querySelectorAll('.lk_content__purse_tab_content-item');
        let tabName;
        tabNav.forEach(item => {
            item.addEventListener('click', selectTabNav)
        });

        function selectTabNav() {
            tabNav.forEach(item => {
                item.classList.remove('lk_content__purse_tab-item-active');
            });
            this.classList.add('lk_content__purse_tab-item-active');
            tabName = this.getAttribute('data-tab-name');
            selectTabContent(tabName);
        }

        function selectTabContent(tabName) {
            tabContent.forEach(item => {
                item.classList.contains(tabName) ? item.classList.add('lk_content__purse_tab_content-item-active') : item.classList.remove('lk_content__purse_tab_content-item-active');
            });
        }
    };
    tab();
}catch (e){
    console.log(e);
}


/* авторесайз текстареа в сообщениях */
try {
    if (document.querySelector('.lk_content__message-inputmessage')) {
        let textarea = document.querySelector('#message-textarea');

        textarea.addEventListener('keyup', function () {
            if (this.scrollTop > 0) {
                this.style.height = this.scrollHeight + "px";
            }
        });

        let lkContentMessageBody = document.querySelector('.lk_content__message-body');
        lkContentMessageBody.scrollTop = 9999;
    }
}catch (e){
    console.log(e);
}

/* Продажа покупка долга */

try {

    let lkContentAdvertPageStart = document.querySelector('.lk_content__advert_page-start'),
        lkAdvertPageNext = document.querySelector('#lk_advert_page__next'),
        lkContentAdvertPageGive = document.querySelector('.lk_content__advert_page-give'),
        lkContentAdvertPageTake = document.querySelector('.lk_content__advert_page-take'),
        lkContentAdvertPageWarning = document.querySelector('.lk_content__advert_page-warning');

    console.log(lkContentAdvertPageGive);
    console.log(lkContentAdvertPageTake);

    lkContentAdvertPageGive.style.display = 'none';
    lkContentAdvertPageTake.style.display = 'none';


    let saleOrBuy = document.getElementsByName('giveOrTake');
    for(let i = 0; i < saleOrBuy.length; i++){
        saleOrBuy[i].addEventListener('change', checkSaleOrBuy);
    }

    function checkSaleOrBuy() {

        /*if(this.value == 'give'){
            lkAdvertPageNext.setAttribute('data-type', 'give');
        }else if(this.value == 'take'){
            lkAdvertPageNext.setAttribute('data-type', 'take');
        }*/

        if(this.checked = true){
            lkAdvertPageNext.setAttribute('data-type', 'give');
        }else{
            lkAdvertPageNext.setAttribute('data-type', 'take');
        }

    }

    lkAdvertPageNext.addEventListener('click', () => {
        if(lkAdvertPageNext.getAttribute('data-type') === 'give'){
            lkContentAdvertPageGive.style.display = 'block';
            lkContentAdvertPageStart.style.display = 'none';
        }else if(lkAdvertPageNext.getAttribute('data-type') === 'take'){
            lkContentAdvertPageGive.style.display = 'block';
            lkContentAdvertPageStart.style.display = 'none';
        }else{
            lkContentAdvertPageWarning.innerHTML = 'Пожалуйста, выберите тип объявления!';
        }
    });


}catch (e){
    console.log(e);
}

try {
    let menuItems = document.querySelectorAll('.lk_content__menu-item');
    menuItems.forEach((item) => {
        if (document.location.search == item.getAttribute('a')) {
            item.classList.add('lk_content__menu-item-active');
        }
    });
}catch (e){
    console.log(e);
}
